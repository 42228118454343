<template>
  <div
      class="container-fluid"
      :class="containerClass"
  >
    <d-title
        title="views.dashboard.content.activities.title"
        container-class="ml-3"
    />
  </div>
</template>
<script>
  export default {
    props: {
      containerClass: {
        type: String,
        default: ''
      }
    },
    components: {
    },
    methods: {
      cols (x) {
        if (this.$store.getters["layout/getInnerWidth"] < 1432 && this.$store.getters["layout/getInnerWidth"] > 1151)
        {
          return '6';
        } else if (this.$store.getters["layout/getInnerWidth"] <= 1151) {
          return '12';
        } else {
          return x;
        }
      },
      bookingsClass (x) {
        if (this.$store.getters["layout/getInnerWidth"] <= 1151)
        {
          return x;
        } else {
          return '';
        }
      },
      thirdCol (x) {
        if (this.$store.getters["layout/getInnerWidth"] < 1332)
        {
          return '12';
        } else {
          return x;
        }
      },
      eventsClass (x) {
        if (this.$store.getters["layout/getInnerWidth"] < 1532)
        {
          return x;
        } else {
          return '';
        }
      }
    },
  }
</script>

